<template>
  <div class="dashboard-card" :class="{ 'is-loading': !analyticsLoaded }">

    <v-card elevation="0">
      <v-card-title>
        <v-icon color="primary">mdi-chart-box</v-icon>
        {{ $t('dashboard.survey.title') }}
      </v-card-title>

      <v-card-text>
        <div v-if="analyticsLoaded && analytics.items.length > 0" class="sp-flex sp-flex-dir-col analytics-container">
          <div v-for="analytics_item in analytics.items" :key="analytics_item.content.id"
               class="analytics-item survey-item">
            <a :href="'/surveys/' + analytics_item.content.id"></a>
            {{ analytics_item.content.title }}<span
              class="counter">{{ analytics_item.read_total + ' von ' + analytics_item.total }}</span>
            <br>
            <em class="small">{{ analytics_item.content.start_date | momentDate }} -
              {{ analytics_item.content.end_date | momentDate }}</em>
          </div>
        </div>

        <div v-if="analyticsLoaded && analytics.items.length == 0" class="sp-flex sp-flex-dir-col full-width analytics-container">
          {{ $t('dashboard.survey.no-content') }}
        </div>

        <div v-if="!analyticsLoaded" class="analytics-loading-wrapper">
          <span v-if="!analyticsError" class="analytics-loading"></span>
        </div>

        <div v-if="analyticsError" class="sp-flex sp-flex-dir-col">
          <span>{{ $t('dashboard.survey.no-analytics-data') }}.</span>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      analytics: {},
      analyticsLoaded: false,
      analyticsError: false
    }
  },
  created() {
    this.getAnalyticsData()
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  methods: {
    getAnalyticsData() {
      HTTP.get('analytics/surveys')
        .then(function (response) {
          this.analytics = response.data
          this.analyticsLoaded = true
        }.bind(this))
        .catch(function () {
          this.analyticsError = true
        }.bind(this))
        .finally(() => {
          this.$emit('finished')
        })
    }
  }
}
</script>
