<template>
  <div class="dashboard-card" :class="{ 'is-loading': !analyticsLoaded }">

    <v-card elevation="0">
      <v-card-title>
        <v-icon color="primary">mdi-calendar-remove</v-icon>
        {{ $t('dashboard.absences.title') }} <span class="analytics-bubble"
          v-if="analytics.totalAbsences && analytics.totalAbsences > 10">
          {{ analytics.totalAbsences }}</span>
      </v-card-title>

      <v-card-text>
        <div v-if="analyticsLoaded && analytics.items.length > 0" class="sp-flex sp-flex-dir-col analytics-container">
          <div v-for="analytics_item in analytics.items" :key="analytics_item.id" class="analytics-item absence-item">
            <a :href="'/absences/' + analytics_item.id"></a>
            <span v-if="analytics_item.title !== ''">{{ analytics_item.title }}</span>
            <span v-else>{{ analytics_item.reason.name }}</span>
            <span class="counter">{{ analytics_item.start_date | momentDate }}</span>
            <br>
            <em class="small">
              {{ analytics_item.reason.name + ' | ' + analytics_item.author.firstname + ' ' + analytics_item.author.name }}
            </em>
            <span class="counter-sub" v-if="!analytics_item.allday">
              {{ analytics_item.start_date | momentTime }} Uhr
            </span>
          </div>
        </div>

        <div v-if="analyticsLoaded && analytics.items.length == 0" class="sp-flex sp-flex-dir-col full-width analytics-container">
          {{ $t('dashboard.absences.no-content') }}
        </div>

        <div v-if="!analyticsLoaded" class="analytics-loading-wrapper">
          <span v-if="!analyticsError" class="analytics-loading"></span>
        </div>

        <div v-if="analyticsError" class="sp-flex sp-flex-dir-col">
          <span>{{ $t('dashboard.absences.no-analytics-data') }}</span>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'

export default {
  name: 'DashboardAbsences',
  components: {},
  data() {
    return {
      analytics: {
        items: null,
        totalAbsences: null
      },
      analyticsLoaded: false,
      analyticsError: false
    }
  },
  created() {
    this.getAnalyticsData()
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  methods: {
    getAnalyticsData() {
      HTTP.get('absences/dashboarddata')
        .then(function (response) {
          this.analytics.items = response.data.data
          this.analytics.totalAbsences = response.data.total
          this.analyticsLoaded = true
        }.bind(this))
        .catch(function () {
          this.analyticsError = true
        }.bind(this))
        .finally(() => {
          this.$emit('finished')
        })
    }
  }
}
</script>
