<template>
  <div>

    <toolbar :dataLoaded="true">{{ $t('dashboard.title') }}</toolbar>

    <v-container fluid class="dashboard" v-if="modules">

      <h2 v-if="user" class="full-width">{{ $t('dashboard.welcome') }} {{ user.firstname }} {{ user.name }}</h2>

      <loading :dataLoaded="finished === countBoards"></loading>

      <v-row :class="[{'show': showDashboard }]" class="dashboard-wrapper" v-masonry="'dashboard'" transition-duration="0.3s" item-selector=".dashboard-item">

        <dashboard-news-favorites v-if="user != null && moduleEnabled('news') && ['admin'].includes(user.role)" v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <dashboard-jobs-favorites v-if="user != null && moduleEnabled('jobs') && ['admin'].includes(user.role)" v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <dashboard-absences-open v-if="user != null && moduleEnabled('absence') && ['admin'].includes(user.role)" v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <dashboard-surveys-running v-if="user != null && moduleEnabled('surveys') && ['admin'].includes(user.role)" v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <dashboard-users-token v-if="user != null && ['admin'].includes(user.role)" v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <dashboard-users-os v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <dashboard-store-urls v-masonry-tile @finished="countFinished" class="dashboard-item"/>

        <!-- <dashboard-users-per-day/> -->

      </v-row>

    </v-container>
  </div>
</template>

<script>
import store from 'store'
import { hasModule } from '@/services/LicenseService'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import DashboardNewsFavorites from './dashboard/NewsFavorites'
import DashboardJobsFavorites from './dashboard/JobsFavorites'
import DashboardAbsencesOpen from './dashboard/AbsencesOpen'
import DashboardSurveysRunning from './dashboard/SurveysRunning'
import DashboardUsersToken from './dashboard/UsersWithToken'
import DashboardStoreUrls from './dashboard/StoreUrls'
import DashboardUsersOs from './dashboard/UsersOs'
import DashboardUsersPerDay from '@/views/dashboard/UsersPerDay'
import {VueMasonryPlugin} from 'vue-masonry'
import Vue from 'vue'

Vue.use(VueMasonryPlugin)

export default {
  name: 'Dashboard',
  components: {
    DashboardUsersPerDay,
    DashboardUsersOs,
    DashboardStoreUrls,
    DashboardNewsFavorites,
    DashboardJobsFavorites, 
    DashboardAbsencesOpen,
    DashboardSurveysRunning,
    DashboardUsersToken,
    toolbar,
    VueMasonryPlugin,
    loading
  },
  data() {
    return {
      user: null,
      modules: [],
      finished: 0,
      showDashboard: false,
      countBoards: 2
    }
  },
  mounted() {
    this.user = store.get('user')
    let modules = ['news', 'jobs', 'absence', 'surveys']

    modules.forEach(module => {
      if (this.user != null && this.moduleEnabled(module) && ['admin'].includes(this.user.role)){
        this.countBoards++
      }
    })

    if (this.user != null && ['admin'].includes(this.user.role)) {
      this.countBoards++
    }
  },
  methods: {
    moduleEnabled(string) {
      return hasModule(string)
    },
    countFinished() {
      this.finished++
      if (this.finished === this.countBoards){
        this.$nextTick(function () {
          this.$redrawVueMasonry('dashboard')
          setTimeout(() => {
            this.showDashboard = true
          }, 350)
        }.bind(this))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-item { 
  margin: 12px;
  width: calc(50% - 24px);
  margin-bottom: 32px;
}

@media screen and (min-width: 1400px) {

  .dashboard-item {
    width: calc(33.333% - 24px);
  }

}
</style>